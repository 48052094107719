import React, { useEffect } from 'react';
import gsap from 'gsap';
import '../ServicesBanner/PartnerBanner.css';

const PartnersBanner = () => {
  useEffect(() => {
    // Select all the boxes within the wrapper
    const wrapper = document.querySelector(".wrapper");

    const boxes = gsap.utils.toArray(".box");

    // Call the horizontalLoop helper function
    const loop = horizontalLoop(boxes, { paused: false, repeat: -1,  speed: .5 });

    // Optional: add functionality to control the loop if needed
    loop.play(); // Ensure the loop starts
  }, []);

  return (
    <div className="expertise-banner">
      <div className="expertise-title"><h1>Expertise</h1></div>
      <div className="wrapper">
        <div className="boxes">
          <div className="box">Customer Marketing</div>
          <div className="box">CRM Implementation</div>
          <div className="box">Cyber Security</div>
          <div className="box">Tool Analysis</div>
          <div className="box">Full Stack Development</div>
          <div className="box">IT Infrastructure</div>
          <div className="box">Podcasting</div>
          <div className="box">Product Development</div>
          <div className="box">Project Management</div>
          <div className="box">Sales Enablement</div>
          <div className="box">SEO & SEM</div>
          <div className="box">Customer Success</div>
          <div className="box">Go-To-Market</div>
          <div className="box">DevOps</div>
          <div className="box">UI/UX Design</div>
          <div className="box">Product Strategy</div>
          <div className="box">Business Development</div>
          <div className="box">Demand Generation</div>
          <div className="box">Saas Development</div>
          <div className="box">Cloud Architecture</div>
          <div className="box">Process Management</div>
        </div>
      </div>
    </div>
  );
};

// Horizontal loop helper function
function horizontalLoop(items, config) {
	items = gsap.utils.toArray(items);
	config = config || {};
	let tl = gsap.timeline({repeat: config.repeat, paused: config.paused, defaults: {ease: "none"}, onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)}),
		length = items.length,
		startX = items[0].offsetLeft,
		times = [],
		widths = [],
		xPercents = [],
		curIndex = 0,
		pixelsPerSecond = (config.speed || 1) * 100,
		snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
		totalWidth, curX, distanceToStart, distanceToLoop, item, i;
	gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
		xPercent: (i, el) => {
			let w = widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
			xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / w * 100 + gsap.getProperty(el, "xPercent"));
			return xPercents[i];
		}
	});
	gsap.set(items, {x: 0});
	totalWidth = items[length-1].offsetLeft + xPercents[length-1] / 100 * widths[length-1] - startX + items[length-1].offsetWidth * gsap.getProperty(items[length-1], "scaleX") + (parseFloat(config.paddingRight) || 0);
	for (i = 0; i < length; i++) {
		item = items[i];
		curX = xPercents[i] / 100 * widths[i];
		distanceToStart = item.offsetLeft + curX - startX;
		distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
		tl.to(item, {xPercent: snap((curX - distanceToLoop) / widths[i] * 100), duration: distanceToLoop / pixelsPerSecond}, 0)
		  .fromTo(item, {xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)}, {xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false}, distanceToLoop / pixelsPerSecond)
		  .add("label" + i, distanceToStart / pixelsPerSecond);
		times[i] = distanceToStart / pixelsPerSecond;
	}
	function toIndex(index, vars) {
		vars = vars || {};
		(Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
		let newIndex = gsap.utils.wrap(0, length, index),
			time = times[newIndex];
		if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
			vars.modifiers = {time: gsap.utils.wrap(0, tl.duration())};
			time += tl.duration() * (index > curIndex ? 1 : -1);
		}
		curIndex = newIndex;
		vars.overwrite = true;
		return tl.tweenTo(time, vars);
	}
	tl.next = vars => toIndex(curIndex+1, vars);
	tl.previous = vars => toIndex(curIndex-1, vars);
	tl.current = () => curIndex;
	tl.toIndex = (index, vars) => toIndex(index, vars);
	tl.times = times;
  tl.progress(1, true).progress(0, true); // pre-render for performance
  if (config.reversed) {
    tl.vars.onReverseComplete();
    tl.reverse();
  }
	return tl;
}
export default PartnersBanner;
